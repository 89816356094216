import React from "react";
import LoadingSpinner from "../loadingSpinner/loadingSpinner";

interface ButtonProps {
    children?: React.ReactNode;
    isLoading?: boolean;
    disabled?: boolean;
    className?: string;
    type?: string;
    onClick?: Function;
}

const Button: React.FC<ButtonProps> = ({ className, onClick, disabled, type = "button", children, isLoading }) => {
    return (
        <button
            // @ts-ignore
            onClick={onClick}
            type={type === "submit" ? "submit" : "button"}
            className={`inline-flex items-center py-2.5 px-5 mr-2 ${className}`}
            disabled={isLoading || disabled}
        >
            {isLoading && (
                <LoadingSpinner />
            )}
            {children}
        </button>
    );
};

Button.defaultProps = {
    isLoading: false
};

Button.displayName = "Button";

export default Button;