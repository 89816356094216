import React from "react";
import Button from "../design/atoms/button/button";
import { useRouter } from "next/router";
import { devFeatures } from "../utils/environmentTools/checkEnv";

const Custom404 = () => {
    const router = useRouter();
    const backUrl = router.query.backUrl !== undefined ? router.query.backUrl : "/";
    return (
        <>
            {devFeatures() && (<p>backUrl: {backUrl}</p>)}
            <div className="flex items-center justify-center h-screen mx-2 my-2 overflow-hidden ">
                <div className="px-12 py-8 rounded shadow-lg">
                    <div className="mb-2 text-xl font-bold">
                        404 - Sorry, could not find this page.
                    </div>
                    <div className={"text-center mt-10"}>
                        {/*@ts-ignore*/}
                        <Button className={"solid-button"} onClick={() => router.push(`${backUrl}`)}>Take me
                            back</Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Custom404;
